import { lazy, useEffect, useRef, useState } from 'react';

// project imports
import { useNavigate, useParams } from 'react-router-dom';
import SurveyService from 'services/survey.service';
import MultiStepForm from './MultiStepForm';
import * as yup from 'yup';
import { Box, Button, Card, CardContent, Grid, Skeleton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Loadable from 'ui-component/Loadable';
import useMasterSurveyResponseStore from 'zustand_store/useMasterSurveyResponseStore';
import { find, first, isEmpty, isUndefined, now } from 'lodash';
import FormStep from './FormStep';
import AuthWrapper1 from '../AuthWrapper1';
import AnswerService from 'services/answer.service';
import ResponseService from 'services/response.service';
import useAuth from 'hooks/useAuth';
import YupValidatorGenerator from 'utils/YupValidatorGenerator';
import { IconAxe, IconSquareMinus } from '@tabler/icons';
import useQuestionStore from 'zustand_store/useQuestionStore';
import MessageQuestion from 'views/questions/MessageQuestion';
import ListSkeleton from 'ui-component/custom/ListSkeleton';
import Logo from 'ui-component/Logo';
import LogoSection from 'layout/MainLayout/LogoSection';

const SurveyHandler = () => {
    const { shortcode } = useParams();
    const [questions, setQuestions] = useState([]);
    const [isinitiallyLoaded, setIsinitiallyLoaded] = useState(false);
    const [yupValidatorSchema, setYupValidatorSchema] = useState({});
    const stateQuestions = useMasterSurveyResponseStore((state) => state.questions);
    const SET_DANGEROULSY_STATE_INTO_STORE = useMasterSurveyResponseStore((state) => state.SET_DANGEROULSY_STATE);
    const [survey, setSurvey] = useState({});
    const [resolveCurrentQuestionAnswer, setResolveCurrentQuestionAnswer] = useState('');
    const [currentQuestion, setCurrentQuestion] = useState({});
    const [SelectedQuestionComponent, setSelectedQuestionComponent] = useState({});
    const SET_QUESTIONS_INTO_STORE = useMasterSurveyResponseStore((state) => state.SET_QUESTONS);
    const RESET_RESPONSES_TO_STORE = useMasterSurveyResponseStore((state) => state.RESET_RESPONSES);

    const SET_CURRENT_QUESTON_INTO_STORE = useMasterSurveyResponseStore((state) => state.SET_CURRENT_QUESTON);
    const getNextQuestion = useMasterSurveyResponseStore((state) => state.getNextQuestion);
    const getPrevQuestion = useMasterSurveyResponseStore((state) => state.getPrevQuestion);
    const responseTracker = useMasterSurveyResponseStore((state) => state.responseTracker);
    const ADD_RESPONSE_TO_STORE = useMasterSurveyResponseStore((state) => state.ADD_RESPONSE);
    const SET_SURVEY_QUESTIONS_TO_STORE = useQuestionStore((state) => state.SET_SURVEY_QUESTIONS);
    const TRACK_RESPONSES_TO_STORE = useMasterSurveyResponseStore((state) => state.TRACK_RESPONSES);
    const SET_RULE_ENGINE_INTO_STORE = useMasterSurveyResponseStore((state) => state.SET_RULE_ENGINE);
    const SET_USERID_SURVEYTOKEN_INTO_STORE = useMasterSurveyResponseStore((state) => state.SET_USERID_SURVEYTOKEN);
    const STATE_SETTER_INTO_STORE = useMasterSurveyResponseStore((state) => state.STATE_SETTER);
    const responses = useMasterSurveyResponseStore((state) => state.responseContainer.responses);
    const hasPrevious = useMasterSurveyResponseStore((state) => state.hasPreviousQuestion);
    const STORE_STATE = useMasterSurveyResponseStore((state) => state);
    const SET_DANGEROULSY_RESPONSETRACKER = useMasterSurveyResponseStore((state) => state.SET_DANGEROULSY_RESPONSETRACKER);
    const storeCurrentShortcode = useMasterSurveyResponseStore((state) => state.survey_short_code);
    const multiStepFormEl = useRef();
    const responseContainer = useMasterSurveyResponseStore((state) => state.responseContainer);

    const theme = useTheme();
    const navigate = useNavigate();
    const { user } = useAuth();

    const handleQuit = () => {
        RESET_RESPONSES_TO_STORE();
        SET_DANGEROULSY_RESPONSETRACKER([]);
        navigate('/surveys');
    };

    const renderInitialData = async () => {
        //  Get Questions with survey information
        try {
            const surveyResponse = await SurveyService.surveyHandler(shortcode);

            setSurvey(surveyResponse.data); // local
            setQuestions(surveyResponse.data.questions); // local
            // await SET_QUESTIONS_INTO_STORE(data.questions); // State Manager
            SET_SURVEY_QUESTIONS_TO_STORE(surveyResponse.data.questions);

            // if the user is authenticate then check if the survey was resumed or not
            if (user) {
                const response = await SurveyService.isResumedSurvey(surveyResponse.data.id, user.id);
                // eslint-disable-next-line no-extra-boolean-cast
                if (!!response.data) {
                    // The survey is resumed
                    STATE_SETTER_INTO_STORE(response.data); // STATE MANAGER
                    // return false;
                } else {
                    // The Survey is not resumed, it's new
                    RESET_RESPONSES_TO_STORE();
                    SET_USERID_SURVEYTOKEN_INTO_STORE(user.id, surveyResponse.data.meta.short_code); // State Manager
                    SET_QUESTIONS_INTO_STORE(surveyResponse.data.questions); // State Manager
                    SET_RULE_ENGINE_INTO_STORE(surveyResponse.data.meta.rule_engine || []); // State Manager
                    // Setting first Question by default
                    // if (responseTracker.length === 0) {
                    // console.log('first', first(Object.values(data.questions)));
                    setCurrentQuestion(first(Object.values(surveyResponse.data.questions)));
                    // }
                }
            } else {
                SET_USERID_SURVEYTOKEN_INTO_STORE(0, surveyResponse.data.meta.short_code); // State Manager
                SET_QUESTIONS_INTO_STORE(surveyResponse.data.questions); // State Manager
                SET_RULE_ENGINE_INTO_STORE(surveyResponse.data.meta.rule_engine || []); // State Manager
                // Setting first Question by default
                if (responseTracker.length === 0) {
                    setCurrentQuestion(first(Object.values(surveyResponse.data.questions)));
                }
            }
            setIsinitiallyLoaded(true);
        } catch (error) {
            if (error.code === 'error') {
                navigate(error.redirect_to, {
                    state: {
                        code: error.code,
                        msg: error.msg
                    }
                });
            }
        }
    };

    //  Master Submit Handler
    const submitHandler = async (values, actions) => {
        setIsinitiallyLoaded(false);
        ADD_RESPONSE_TO_STORE(currentQuestion.id, values.answer, values.answered_at);
        const id = currentQuestion.id;
        const answer = values.answer;
        let scorePayload = [];

        if (survey.survey_type === 'Assessment' && responseContainer.responses.length > 0) {
            scorePayload = responseContainer.responses.map((res) => ({
                choiceId: Number(res.answer.value),
                questionId: res.qID
            }));
            // const { data: response } = await SurveyService.assessAnswerScore({ choices: scorePayload });
        }

        //  Only Take Valid Responses ( logical )
        if (responseTracker?.length !== 0) {
            const filteredResponses = responses.filter(
                (response) => responseTracker.includes(response.qID) || response.qID === currentQuestion.id
            );
            // Submit All Answers
            await AnswerService.create({
                surveyToken: survey.survey_token,
                answers: filteredResponses,
                choices: scorePayload,
                meta: {
                    ...STORE_STATE.meta,
                    survey_completed_time: now()
                }
            });
        } else {
            // Submit All Answers
            await AnswerService.create({
                surveyToken: survey.survey_token,
                answers: responses,
                choices: scorePayload,
                meta: {
                    ...STORE_STATE.meta,
                    survey_completed_time: now()
                }
            });
        }

        actions.resetForm();
        RESET_RESPONSES_TO_STORE();
        if (survey.survey_type === 'Poll') {
            window.location.href = '/survey-results';
            // navigate('/survey-results');
        } else {
            window.location.href = '/survey-success';
        }
    };

    const validationSchema = yup.lazy((value) =>
        yup.object().shape({
            answer: yupValidatorSchema && yupValidatorSchema.generateSchema(currentQuestion.meta, value, currentQuestion)
        })
    );

    const fetchNextQuestion = async (currentQuestionID, values, actions) => {
        const currentQuestion = find(questions, { id: currentQuestionID });
        if (!hasPrevious(currentQuestion)) {
            await AnswerService.surveyStarted(survey.survey_token);
            SET_DANGEROULSY_STATE_INTO_STORE({
                ...STORE_STATE,
                meta: {
                    survey_started_time: now()
                }
            });
        }

        ADD_RESPONSE_TO_STORE(currentQuestionID, values.answer, values.answered_at);
        const nextQuestion = getNextQuestion(currentQuestionID);
        actions.resetForm();
        TRACK_RESPONSES_TO_STORE(currentQuestionID, nextQuestion);
        setCurrentQuestion(nextQuestion);

        if (user) {
            // Tracks Response data on backend by cache only when the user is authenticate
            ResponseService.trackResponseOnCache({
                survey_id: survey.id,
                data: STORE_STATE
            });
        }
    };

    const fetchPrevQuestion = (currentQuestionID, formik) => {
        ADD_RESPONSE_TO_STORE(currentQuestionID, formik.values.answer, formik.values.answered_at);
        const prevQuestion = getPrevQuestion(currentQuestionID);
        setCurrentQuestion(prevQuestion);
        formik.setFieldValue('answer', '');
        if (user) {
            // Tracks Response data on backend by cache only when the user is authenticate
            ResponseService.trackResponseOnCache({
                survey,
                data: STORE_STATE
            });
        }
    };

    const currentQuestionChangeHandler = (currentQuestion) => {
        const Component = Loadable(lazy(() => import(`views/questions/${currentQuestion?.meta.identifier}`)));
        setSelectedQuestionComponent(<Component questionData={currentQuestion} />);
        setResolveCurrentQuestionAnswer((prevValue) => {
            const existingAnswer = find(responses, { qID: currentQuestion.id });
            if (existingAnswer) {
                return existingAnswer.answer;
            }
            return '';
        });
        SET_CURRENT_QUESTON_INTO_STORE(currentQuestion);
    };

    // It triggers next question/submit button
    const handleSkip = () => multiStepFormEl.current && multiStepFormEl.current.click();

    useEffect(() => {
        if (!isEmpty(currentQuestion)) {
            currentQuestionChangeHandler(currentQuestion);
            setYupValidatorSchema(new YupValidatorGenerator());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentQuestion]);

    useEffect(() => {
        // if the respondent resume the survey then go to the last question he answered
        if (responseTracker.length > 0) {
            const currentQuestionID = responseTracker[responseTracker.length - 1];
            const nextQuestion = getNextQuestion(currentQuestionID);
            TRACK_RESPONSES_TO_STORE(currentQuestionID, nextQuestion);
            setCurrentQuestion(nextQuestion);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [responseTracker]);

    const multiStepFormProps = { currentQuestion, fetchNextQuestion, fetchPrevQuestion, multiStepFormEl };

    const handleWelcomePageButton = (currentQuestionID) => {
        const nextQuestion = getNextQuestion(currentQuestionID);
        setCurrentQuestion(nextQuestion);
    };

    const handleForwardButton = async (currentQuestionID) => {
        setIsinitiallyLoaded(false);

        const nextQuestion = getNextQuestion(currentQuestionID);
        console.log('Flow 2', nextQuestion, responses, responseContainer);
        if (!isUndefined(nextQuestion)) {
            setCurrentQuestion(nextQuestion);

            ADD_RESPONSE_TO_STORE(currentQuestionID, '', new Date());

            TRACK_RESPONSES_TO_STORE(currentQuestionID, nextQuestion);
            setIsinitiallyLoaded(true);

            if (user) {
                // Tracks Response data on backend by cache only when the user is authenticate
                ResponseService.trackResponseOnCache({
                    survey_id: survey.id,
                    data: STORE_STATE
                });
            }
        } else {
            let scorePayload = [];

            if (survey.survey_type === 'Assessment' && responseContainer.responses.length > 0) {
                scorePayload = responseContainer.responses.map((res) => ({
                    choiceId: Number(res.answer.value),
                    questionId: res.qID
                }));
            }

            //  Only Take Valid Responses ( logical )
            if (responseTracker?.length !== 0) {
                const filteredResponses = responses.filter(
                    (response) => responseTracker.includes(response.qID) || response.qID === currentQuestion.id
                );
                // Submit All Answers
                await AnswerService.create({
                    surveyToken: survey.survey_token,
                    answers: filteredResponses,
                    choices: scorePayload,
                    meta: {
                        ...STORE_STATE.meta,
                        survey_completed_time: now()
                    }
                });
            } else {
                // Submit All Answers
                await AnswerService.create({
                    surveyToken: survey.survey_token,
                    answers: responses,
                    choices: scorePayload,
                    meta: {
                        ...STORE_STATE.meta,
                        survey_completed_time: now()
                    }
                });
            }

            // formik.resetForm();
            RESET_RESPONSES_TO_STORE();
            if (survey.survey_type === 'Poll') {
                window.location.href = '/survey-results';
                // navigate('/survey-results');
            } else {
                window.location.href = '/survey-success';
            }
        }
    };

    const handleBackOnScreen = (currentQuestionID) => {
        const prevQuestion = getPrevQuestion(currentQuestionID);
        setCurrentQuestion(prevQuestion);
    };

    useEffect(() => {
        renderInitialData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <AuthWrapper1>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    justifyContent: 'space-between',
                    alignContent: 'normal'
                }}
            >
                <Box
                    sx={{
                        display: 'block',
                        flexGrow: 0,
                        flexShrink: 1,
                        flexBasis: 'auto',
                        alignSelf: 'auto',
                        order: 0,
                        marginLeft: '20px',
                        marginTop: '20px'
                    }}
                    xs={12}
                >
                    {survey?.meta?.logo && survey?.survey_logo ? (
                        <img src={`${survey?.survey_logo}`} alt="logo" width={150} />
                    ) : (
                        <LogoSection />
                    )}
                </Box>

                <Box
                    alignItems="left"
                    sx={{
                        marginRight: '20px',
                        marginTop: '20px',
                        display: 'block',
                        flexGrow: 0,
                        flexShrink: 1,
                        flexBasis: 'auto',
                        alignSelf: 'auto',
                        order: 0
                    }}
                    xs={12}
                >
                    <Button
                        variant="contained"
                        endIcon={<IconSquareMinus stroke={1.5} size="1.3rem" />}
                        onClick={handleSkip}
                        disabled={!isEmpty(currentQuestion) && currentQuestion?.meta?.required}
                        // alignItems="right"
                        sx={{
                            marginRight: '20px',
                            marginTop: '20px'
                        }}
                    >
                        Skip
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ marginRight: '20px', marginTop: '20px' }}
                        endIcon={<IconAxe stroke={1.5} size="1.3rem" />}
                        onClick={handleQuit}
                    >
                        Quit
                    </Button>
                </Box>
            </Box>

            {isinitiallyLoaded && currentQuestion ? (
                <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 150px)' }}>
                    <Grid item xs={12}>
                        {currentQuestion?.meta?.identifier !== 'MessageQuestion' && (
                            <Typography variant="h1" align="center" sx={{ paddingBottom: '50px' }}>
                                {survey.survey_title}
                            </Typography>
                        )}
                        <Card
                            sx={{
                                backgroundColor: '#ecf0f1',
                                background: currentQuestion.meta.identifier === 'MessageQuestion' ? 'transparent' : theme.palette.grey[50],
                                border: currentQuestion.meta.identifier === 'MessageQuestion' ? 'none' : '1px solid',
                                borderColor: theme.palette.grey[100],
                                textAlign: 'center',
                                // maxWidth: currentQuestion.meta.identifier === 'MessageQuestion' ? 1020 : 1020,
                                maxWidth: 1020,
                                margin: '0 auto',
                                flexDirection: 'column',
                                padding: currentQuestion.meta.identifier === 'MessageQuestion' ? '0px' : '50px'
                            }}
                        >
                            <CardContent
                                sx={{
                                    p: currentQuestion.meta.identifier === 'MessageQuestion' ? 0 : 2, // 2
                                    pb: currentQuestion.meta.identifier === 'MessageQuestion' ? '1px !important' : '16px!important' // 16
                                }}
                            >
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            width: '100%',
                                            margin: '0 auto'
                                        }}
                                    >
                                        {!isEmpty(currentQuestion) &&
                                            !isEmpty(SelectedQuestionComponent) &&
                                            stateQuestions.length !== 0 &&
                                            currentQuestion?.meta?.identifier === 'MessageQuestion' && (
                                                <MessageQuestion
                                                    questionData={currentQuestion}
                                                    handleWelcomePageButton={handleWelcomePageButton}
                                                    handleBackOnScreen={handleBackOnScreen}
                                                    handleForwardButton={handleForwardButton}
                                                />
                                            )}
                                        {!isEmpty(currentQuestion) &&
                                            !isEmpty(SelectedQuestionComponent) &&
                                            stateQuestions.length !== 0 &&
                                            currentQuestion?.meta?.identifier !== 'MessageQuestion' && (
                                                <MultiStepForm
                                                    onSubmit={(values, actions) => submitHandler(values, actions)}
                                                    {...multiStepFormProps}
                                                >
                                                    <FormStep
                                                        initialValues={{
                                                            answer: resolveCurrentQuestionAnswer
                                                        }}
                                                        onSubmit={(values, actions) =>
                                                            fetchNextQuestion(currentQuestion.id, values, actions)
                                                        }
                                                        validationSchema={validationSchema}
                                                    >
                                                        {SelectedQuestionComponent}
                                                    </FormStep>
                                                </MultiStepForm>
                                            )}
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            ) : (
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        minHeight: 'calc(100vh - 150px)',
                        maxWidth: 1020,
                        margin: '0 auto'
                    }}
                >
                    <Grid item xs={12}>
                        <ListSkeleton times={3} />
                    </Grid>
                </Grid>
            )}
        </AuthWrapper1>
    );
};

export default SurveyHandler;
